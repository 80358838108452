<template>
  <div id="page-user-list">
    <vx-card class="mb-base">
      <vs-tabs v-if="loaded">
        <vs-tab
          :label="`Urgent (${ticketData.urgent.length})`"
          icon-pack="feather"
          icon="icon-alert-triangle"
        >
          <div class="tab-text">
            <vs-table
              stripe
              pagination
              max-items="10"
              search
              :data="ticketData.urgent"
            >
              <template slot="header">
                <div class="flex">
                  <h3 class="mr-4">Urgent Ticket</h3>
                  <h3>
                    <vs-chip color="primary">{{
                      ticketData.urgent.length
                    }}</vs-chip>
                  </h3>
                </div>
              </template>

              <template slot="thead">
                <vs-th>Ticket Number</vs-th>
                <vs-th>Date</vs-th>
                <vs-th>Time</vs-th>
                <vs-th>Deadline</vs-th>
                <vs-th>Product</vs-th>
                <vs-th>Customer</vs-th>
                <vs-th>Priority</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].ticket_number">
                    <a
                      class="cursor-pointer"
                      @click="detailTicket(data[indextr].complain_id)"
                      >{{ data[indextr].ticket_number }}</a
                    >
                  </vs-td>

                  <vs-td :data="data[indextr].tanggal">
                    {{ data[indextr].tanggal }}
                  </vs-td>

                  <vs-td :data="data[indextr].waktu">
                    {{ data[indextr].waktu }}
                  </vs-td>

                  <vs-td :data="data[indextr].deadline">
                    <vs-chip transparent color="danger">{{
                      data[indextr].deadline.substring(0, 10)
                    }}</vs-chip>
                  </vs-td>

                  <vs-td :data="data[indextr].product.name">
                    {{ data[indextr].product.name }}
                  </vs-td>

                  <vs-td :data="data[indextr].company.company_name">
                    {{ data[indextr].company.company_name }}
                  </vs-td>

                  <vs-td :data="data[indextr].priority">
                    {{ data[indextr].priority }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-tab>
        <vs-tab
          :label="`Critical (${ticketData.critical.length})`"
          icon-pack="feather"
          icon="icon-alert-triangle"
        >
          <div class="tab-text">
            <vs-table
              stripe
              pagination
              max-items="10"
              search
              :data="ticketData.critical"
            >
              <template slot="header">
                <div class="flex">
                  <h3 class="mr-4">Critical Ticket</h3>
                  <h3>
                    <vs-chip color="primary">{{
                      ticketData.critical.length
                    }}</vs-chip>
                  </h3>
                </div>
              </template>

              <template slot="thead">
                <vs-th>Ticket Number</vs-th>
                <vs-th>Date</vs-th>
                <vs-th>Time</vs-th>
                <vs-th>Deadline</vs-th>
                <vs-th>Product</vs-th>
                <vs-th>Customer</vs-th>
                <vs-th>Priority</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].ticket_number">
                    <a
                      class="cursor-pointer"
                      @click="detailTicket(data[indextr].complain_id)"
                      >{{ data[indextr].ticket_number }}</a
                    >
                  </vs-td>

                  <vs-td :data="data[indextr].tanggal">
                    {{ data[indextr].tanggal }}
                  </vs-td>

                  <vs-td :data="data[indextr].waktu">
                    {{ data[indextr].waktu }}
                  </vs-td>

                  <vs-td :data="data[indextr].deadline">
                    <vs-chip transparent color="danger">{{
                      data[indextr].deadline.substring(0, 10)
                    }}</vs-chip>
                  </vs-td>

                  <vs-td :data="data[indextr].product.name">
                    {{ data[indextr].product.name }}
                  </vs-td>

                  <vs-td :data="data[indextr].company.company_name">
                    {{ data[indextr].company.company_name }}
                  </vs-td>

                  <vs-td :data="data[indextr].priority">
                    {{ data[indextr].priority }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-tab>
        <vs-tab
          :label="`Emergency (${ticketData.emergency.length})`"
          icon-pack="feather"
          icon="icon-alert-triangle"
        >
          <div class="tab-text">
            <vs-table
              stripe
              pagination
              max-items="10"
              search
              :data="ticketData.emergency"
            >
              <template slot="header">
                <div class="flex">
                  <h3 class="mr-4">Emergency Ticket</h3>
                  <h3>
                    <vs-chip color="primary">{{
                      ticketData.emergency.length
                    }}</vs-chip>
                  </h3>
                </div>
              </template>

              <template slot="thead">
                <vs-th>Ticket Number</vs-th>
                <vs-th>Date</vs-th>
                <vs-th>Time</vs-th>
                <vs-th>Deadline</vs-th>
                <vs-th>Product</vs-th>
                <vs-th>Customer</vs-th>
                <vs-th>Priority</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].ticket_number">
                    <a
                      class="cursor-pointer"
                      @click="detailTicket(data[indextr].complain_id)"
                      >{{ data[indextr].ticket_number }}</a
                    >
                  </vs-td>

                  <vs-td :data="data[indextr].tanggal">
                    {{ data[indextr].tanggal }}
                  </vs-td>

                  <vs-td :data="data[indextr].waktu">
                    {{ data[indextr].waktu }}
                  </vs-td>

                  <vs-td :data="data[indextr].deadline">
                    <vs-chip transparent color="danger">{{
                      data[indextr].deadline.substring(0, 10)
                    }}</vs-chip>
                  </vs-td>

                  <vs-td :data="data[indextr].product.name">
                    {{ data[indextr].product.name }}
                  </vs-td>

                  <vs-td :data="data[indextr].company.company_name">
                    {{ data[indextr].company.company_name }}
                  </vs-td>

                  <vs-td :data="data[indextr].priority">
                    {{ data[indextr].priority }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-tab>
        <vs-tab
          :label="`Major (${ticketData.major.length})`"
          icon-pack="feather"
          icon="icon-alert-octagon"
        >
          <vs-table
            stripe
            pagination
            max-items="10"
            search
            :data="ticketData.major"
          >
            <template slot="header">
              <div class="flex">
                <h3 class="mr-4">Major Ticket</h3>
                <h3>
                  <vs-chip color="primary">{{
                    ticketData.major.length
                  }}</vs-chip>
                </h3>
              </div>
            </template>

            <template slot="thead">
              <vs-th>Ticket Number</vs-th>
              <vs-th>Date</vs-th>
              <vs-th>Time</vs-th>
              <vs-th>Deadline</vs-th>
              <vs-th>Product</vs-th>
              <vs-th>Customer</vs-th>
              <vs-th>Priority</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].ticket_number">
                  <a
                    class="cursor-pointer"
                    @click="detailTicket(data[indextr].complain_id)"
                    >{{ data[indextr].ticket_number }}</a
                  >
                </vs-td>

                <vs-td :data="data[indextr].tanggal">
                  {{ data[indextr].tanggal }}
                </vs-td>

                <vs-td :data="data[indextr].waktu">
                  {{ data[indextr].waktu }}
                </vs-td>

                <vs-td :data="data[indextr].deadline">
                  <vs-chip transparent color="danger">{{
                    data[indextr].deadline.substring(0, 10)
                  }}</vs-chip>
                </vs-td>

                <vs-td :data="data[indextr].product.name">
                  {{ data[indextr].product.name }}
                </vs-td>

                <vs-td :data="data[indextr].company.company_name">
                  {{ data[indextr].company.company_name }}
                </vs-td>

                <vs-td :data="data[indextr].priority">
                  {{ data[indextr].priority }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-tab>
        <vs-tab
          :label="`Minor (${ticketData.minor.length})`"
          icon-pack="feather"
          icon="icon-minus-circle"
        >
          <vs-table
            stripe
            pagination
            max-items="10"
            search
            :data="ticketData.minor"
          >
            <template slot="header">
              <div class="flex">
                <h3 class="mr-4">Minor Ticket</h3>
                <h3>
                  <vs-chip color="primary">{{
                    ticketData.minor.length
                  }}</vs-chip>
                </h3>
              </div>
            </template>

            <template slot="thead">
              <vs-th>Ticket Number</vs-th>
              <vs-th>Date</vs-th>
              <vs-th>Time</vs-th>
              <vs-th>Deadline</vs-th>
              <vs-th>Product</vs-th>
              <vs-th>Customer</vs-th>
              <vs-th>Priority</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].ticket_number">
                  <a
                    class="cursor-pointer"
                    @click="detailTicket(data[indextr].complain_id)"
                    >{{ data[indextr].ticket_number }}</a
                  >
                </vs-td>

                <vs-td :data="data[indextr].tanggal">
                  {{ data[indextr].tanggal }}
                </vs-td>

                <vs-td :data="data[indextr].waktu">
                  {{ data[indextr].waktu }}
                </vs-td>

                <vs-td :data="data[indextr].deadline">
                  <vs-chip transparent color="danger">{{
                    data[indextr].deadline.substring(0, 10)
                  }}</vs-chip>
                </vs-td>

                <vs-td :data="data[indextr].product.name">
                  {{ data[indextr].product.name }}
                </vs-td>

                <vs-td :data="data[indextr].company.company_name">
                  {{ data[indextr].company.company_name }}
                </vs-td>

                <vs-td :data="data[indextr].priority">
                  {{ data[indextr].priority }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-tab>
        <vs-tab
          :label="`Question (${ticketData.question.length})`"
          icon-pack="feather"
          icon="icon-help-circle"
        >
          <vs-table
            stripe
            pagination
            max-items="10"
            search
            :data="ticketData.question"
          >
            <template slot="header">
              <div class="flex">
                <h3 class="mr-4">Question Ticket</h3>
                <h3>
                  <vs-chip color="primary">{{
                    ticketData.question.length
                  }}</vs-chip>
                </h3>
              </div>
            </template>

            <template slot="thead">
              <vs-th>Ticket Number</vs-th>
              <vs-th>Date</vs-th>
              <vs-th>Time</vs-th>
              <vs-th>Deadline</vs-th>
              <vs-th>Product</vs-th>
              <vs-th>Customer</vs-th>
              <vs-th>Priority</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].ticket_number">
                  <a
                    class="cursor-pointer"
                    @click="detailTicket(data[indextr].complain_id)"
                    >{{ data[indextr].ticket_number }}</a
                  >
                </vs-td>

                <vs-td :data="data[indextr].tanggal">
                  {{ data[indextr].tanggal }}
                </vs-td>

                <vs-td :data="data[indextr].waktu">
                  {{ data[indextr].waktu }}
                </vs-td>

                <vs-td :data="data[indextr].deadline">
                  <vs-chip transparent color="danger">{{
                    data[indextr].deadline.substring(0, 10)
                  }}</vs-chip>
                </vs-td>

                <vs-td :data="data[indextr].product.name">
                  {{ data[indextr].product.name }}
                </vs-td>

                <vs-td :data="data[indextr].company.company_name">
                  {{ data[indextr].company.company_name }}
                </vs-td>

                <vs-td :data="data[indextr].priority">
                  {{ data[indextr].priority }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import tableData from '../tableData.js'

export default {
    data() {
        return {
            loaded: false,
            tableData,
            ticketData: {
                critical: [],
                emergency: [],
                urgent : [],
                major : [],
                minor : [],
                question : []
            }
        }
    },
    methods : {
        detailTicket (id) {
            this.$router.push({
                name: 'trouble-ticket-detail',
                params: { id: this.$secure.encrypt(id) }
            });
        },
        getTicketData (pid, pname) {
            const self = this
            this.$store.dispatch('ticket/fetchTicketsByPriority', {
                priority: pname
            })
            .then(res => {
                const data = res.data.data

                switch (pid) {
                    case 0 :
                        self.ticketData.urgent = data;
                        break;
                    case 1 :
                        self.ticketData.major = data;
                        break;
                    case 2 :
                        self.ticketData.minor = data;
                        break;
                    case 3 :
                        self.ticketData.question = data;
                        break;
                    case 4 :
                        self.ticketData.critical = data;
                        break;
                    case 5 :
                        self.ticketData.emergency = data;
                        break;
                    default :
                        self.ticketData.urgent = data;
                }


                console.log(self.ticketData.opened);
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                if(pname == 'question')
                    this.onLoaded()
            })
        },
        onLoaded(){
            this.loaded = true;
            this.$vs.loading.close()
        },
        loading () {
            this.loaded = false;

            this.$vs.loading({
                type: 'border',
                color: 'primary',
                text: `Wait a minute, It's getting data.`
            })
        },
    },
    created() {
        this.loading()
        this.getTicketData(0, 'urgent')
        this.getTicketData(1, 'major')
        this.getTicketData(2, 'minor')
        this.getTicketData(3, 'question'),
        this.getTicketData(4, 'critical'),
        this.getTicketData(5, 'emergency')
    }
}
</script>

<style>
.vs-tabs--li {
  border: 1px solid #ededed;
  padding: 0 10px;
}

.activeChild {
  border: 1px solid rgba(var(--vs-primary), 0.4) !important;
}
</style>
